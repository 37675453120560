html {
    height: 100%;
}

body {
    background-color: #111;
    color: #FAFAFF;
    margin: 0px;
    font-family: Arial, Helvetica, sans-serif;
    height: 100%;
}

#root {
    height:100%;
}

.App {
    height:100%;
}

button, input[type="submit"], input[type="reset"] {
	background: inherit;
	color: inherit;
	border: none;
	padding: 3px;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

#main {
    animation: fadein 1s;
  }
  @keyframes fadein {
    from { opacity: 0}
    to   { opacity: 1}
}

.header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    background-color: #171717;
    height: 60px;
    width: 100%;
}

.page {
    background-color: #000;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 5%;
    margin-left: 5%;
    height: calc(100% - 60px);
    transition: 0.5s;
    width: 90%;
}

.full-page {
    background-color: #000;
    padding-left: calc(5% + 10px);
    padding-right: calc(5% + 10px);
    height: calc(100% - 60px);
    width: 100%;
}

.headItem:hover {
    color: #CACACC;
    cursor: pointer;
}

.headItem {
    padding-left: 25px;
    padding-right: 25px;
    text-decoration: none;
    color: #FAFAFF;
    transition: 0.5s;
}

.hoverable {
    transition: 0.5s;
    color: #FAFAFF;
    text-decoration: none;
}

.hoverable:hover {
    color: #CACACC;
    cursor: pointer;
}

.button:hover {
    background-color: #FAFAFF;
    color: #000;
    cursor: pointer;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.flex-container-column {
    display: flex;
    flex-direction: column;
}

.flex-item {
    padding-right: 25px;
    padding-left: 25px;
}

.followers {
    padding-right: 15px;
}

.following {
    padding-left: 15px;
}

.button {
    text-decoration: none;
    background-color: #000;
    color: #FAFAFF;
    border-color: white;
    transition: 0.5s;
    border-radius: 5px;
    padding-right: 3px;
    padding-left: 3px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin-left: 25px;
    margin-right: 25px;
    outline: none;
    border: none;
}


@media only screen and (max-width: 768px) {
    .button { 
        text-decoration: none;
        background-color: #000;
        color: #FAFAFF;
        border-color: white;
        transition: 0.5s;
        border-radius: 5px;
        padding-right: 3px;
        padding-left: 3px;
        padding-top: 3px;
        padding-bottom: 3px;
        margin-left: 25px;
        margin-right: 25px;
        outline: none;
        border-style: solid;
        border-width: 1px;
    }
}

.flex-center {
    display: flex;
    justify-content: center;
}

.posts {
    background-color: #000;
    width: 100%;
    height: fit-content;
}